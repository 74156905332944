@include media-breakpoint-up(md){
    .stickysidebar{
        position: sticky;
        top: 90px;
    }
}
.back{
	a{
		display: flex;
		align-items: flex-start;
		padding-left: 1.8rem;
		color: $color2;
		font-weight: 600;
		text-decoration: none;
	}
}
.sidenav{
	padding-top: 42px;
	ul{
		margin:0;
		padding:0;
		li {
			position:relative;
			list-style:none;
			&.selected{
				a{
					color: $color2;
					border-bottom: 1px solid $color2;
				}
			}
			span{
				display:inline-block;
				padding:1.3rem 2rem;
				color: $color2;
				border-bottom: 1px solid $color2;
				font-weight: 500;
			}
			a{
				display:inline-block;
				border-bottom: 1px solid $color1;
				padding: 1.3rem 2.5rem;
				color: $color1;
				// font-family: aktiv-grotesk, sans-serif;
				font-weight: 500;
				text-decoration: none !important;				
				&:hover{
					color:$color2!important;
					border-bottom: 1px solid $color2;
				}
			}
		}
	}
	.sidenav--banner{
		margin-top: 3rem;
		padding-left: 2rem;
		font-size: 1.0rem;
		p{
			margin-bottom: 0.9rem;
		}
	}
}
@include media-breakpoint-down(sm) {
	.back{
		margin-top: 1rem;
		margin-bottom: 0.5rem;
		a{
			align-items: center;
			padding-left: 0rem;
			font-size: 0.9rem;
		}
	}
	.sidenav{
		padding-top: 0;
		margin-bottom: 1.5rem;
		ul{
			li{
				a, span{
					padding:0.3rem 1.5rem;
				}
				span{
					display: none;
				}
			}
		}
		.sidenav--banner{
			margin-top: 1.2rem;
			padding-left: 0.3rem;
			p{
				margin-bottom: 0rem;
			}
		}
	}
}
