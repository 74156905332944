// MODALS
.modal-header{
    border-bottom: none;
}
.modal-body{
    padding: 0 3rem;
}
.modal-footer{
    padding: 2rem calc(3rem - 3px);
    border-top:none;
    justify-content: flex-start;
}
.modal{
    .dropdown{
        .btn{
            // outline: 1px solid red;
            border-bottom: 1px solid #ced4da;
            border-radius: 0;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-content: center;
            padding: 0.1rem .75rem;
            padding-left: 0;
            font-size: 0.9rem;
            font-style: oblique;
            color: $gray-500;
            &:hover{
                color: $color2;
                border-bottom-color: $color2;
                text-decoration: none;
            }
            &:focus{
                outline: 0;
                box-shadow: none;
            }
        }
        .btn[aria-expanded="true"]{
            // background-color: white;
            // color: white;
            // &:hover{
            //     color: white;
            // }
          }
        .dropdown-menu{
            width: 100%;
            margin-top: -1px;
            margin-left: -1px;
            border: 1px solid rgba(0,0,0,.1);
            border-radius: 0 0 .25rem .25rem;
            box-shadow: 2px 5px 9px rgba($color: #000000, $alpha: 0.15);
            padding: 0;
        }
        .dropdown-item{
            text-decoration: none;
            border-bottom: 1px solid rgba(0,0,0,.1);
            padding: .5rem 0.9rem;
            &:last-child{ border:0}
        }
    }
}