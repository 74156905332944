
// NAVTOP
.topnav{
    // outline: 1px solid red;
  ul{
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    li{
      a{
        display: block;
        text-decoration: none;
        padding: .5rem 1rem;
        color: $gray-700;
        font-size: 1.3rem;
        line-height: initial;
        // font-family: aktiv-grotesk, sans-serif;
        font-weight: 500;
        &:hover{
          color: $color2;
        }
      }
      &:first-child a{
          padding-left: 0;
      }
      &.selected{
          a{
            color: $color2;
        }
      }
    }
  }
}
@include media-breakpoint-only(md) {
  .topnav ul li a{
    padding: .5rem .5rem;
    font-size: 1.1rem;
  }
}
