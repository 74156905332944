body{
    font-family: aktiv-grotesk, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: $color1;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    margin-bottom: 1.5rem;
    small{
        display: block;
        font-style: oblique;
    }
}
.bold, strong{
    font-weight: 700;
}
p{
    margin-bottom: 2rem;
}
a{
    color: $color3;
    text-decoration: underline;
    &:hover{
        color: $color1;
    }
    &.text-muted{
        color:$gray-500;
        font-size: 0.95rem;
    }
}
@include media-breakpoint-up(lg) {
    dd{
        margin-bottom: 1.5rem;
    }
}

@include media-breakpoint-down(sm) {
    h1, .h1{ font-size: 7vw; }
    h2, .h2{ font-size: 6vw; }
    h3, .h3{ font-size: 5.5vw; }
    h4, .h4{ font-size: inherit; }
    p{ margin-bottom: 1.5rem;  }
}


.close{
    &:hover{ color: $color3;}
    &:focus{
        outline: 0;
    }
}
