.backTop-container {
	position:fixed;
	z-index:999;
	bottom: 45px;
	height: 40px;
    width: 40px;
    right: 0;
}
#backTop {
	position:absolute;
	z-index:999;
	right:15px;
	width:40px;
	height:40px;
	border-radius:50%;
	background-color:lighten($color1,10%);
	cursor:pointer;
	// transition: all .25s ease;
	&:hover{
		// transform: rotate(360deg);
		background:$color2;
	}
	&:before{
		display:flex;
		align-items: center;
		justify-content: center;
		width:40px !important;
		height:40px !important;
		text-align:center;
		// content:"TOP";
		content: url('../images/angle-up.png');
		// font-family:icomoon;
		color:white;
		font-size:11px;
		line-height: 11px;
	}
}
#backTop.masked {
	opacity:0;
	cursor: default;
}