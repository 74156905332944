//	.navbar-collapse{
//		 box-shadow: inset 0 -15px 0 rgba(5, 5, 5, 0.5);
//	}

@media only screen and (min-width: 1024px) {
	.navbar-collapse.collapse{
		display:none!important;
	}
	.navbar-collapse.collapse.in{
		display:block!important;
	}
}


@media only screen and (max-width: 991px) {
	.navbar-toggle{
		display:block;
	}
}

// MOBILE NAVBURGER
	.mobileNavBurger{
		position: absolute;
		z-index:99999;
		top: 1.3rem;
		right:1rem;
		.navbar-toggle {
			float: left;
			margin: 0;
			height: 50px;
			width: 50px;
			background: none;
			border: 0;
			z-index: 1;
			&:focus{ outline:0}
			.icon-bar {
				float: left;
				width: 100%;
				height: 3px;
				margin-bottom: 7px;
				background: $color2;
				transition: .5s ease;
				&:nth-child(1) {
					transform: rotate(-45deg)translateY(10px)translateX(-12px);
				}
				&:nth-child(2) {
					opacity: 0;
				}
				&:nth-child(3) {
					transform: rotate(45deg)translateY(-1px)translateX(-4px);
				}
			}
		}
		.navbar-toggle.collapsed {
			.icon-bar {
				&:nth-child(1) {
					transform: rotate(0)translateY(0)translateX(0);
				}
				&:nth-child(2) {
					opacity: 1;
				}
				&:nth-child(3) {
					transform: rotate(0)translateY(0)translateX(0);
				}
			}
		}
		@media screen and (min-width : 578px) and (max-width : 768px){
			top: 2.7rem;
		}
	}


// MOBILE NAVPANEL
	.mobileNavPanel{
		width: 100%!important;
        // background: $color1;
        background: $color4;
        margin-bottom: 1.5rem;
		position: relative;
		z-index: 9;
		.innerMobileNavPanel{
			padding: 20px 20px;
		}
		.logo {
			max-width: 65%;
			padding-top: 1.1rem;
			padding-left: 2rem;
			svg, img{
				fill:white
			}
		 }
	}


// MAJORNAV
	.majornav{
		margin: 3rem auto 1.5rem auto;
		.mobileFix{
			display: none;
		}
		ul{
			margin-bottom: 0;
			margin-left: 0;
			padding-left: 0;
			list-style-image: none;
			width:100%;
			border: 0!important;
			li{			
				position:relative;
				margin-top: 0px;
				width: 100% !important;
				display: block;
				float : none;
				clear : both;
				list-style: none;
				&.active a{ 
					color: $color2!important;
				}
				a{
					display: block;
					//min-height: 42px; margin: 16px 0; // === for Google Lighthouse
					// color: white;
					padding: 0.75rem 0!important;
					border-bottom: 1px solid white;
                    color: $color2;
					font-size: 1.3rem!important;
					font-weight: 500;
					text-decoration: none;
					white-space: normal;
					&:hover{color: white; text-decoration: none!important;} // nodig voor mobile touch-testing op desktop
				}
				&:last-child a{ border: 0;}
				&.hasChildren{
					position: relative!important;
					ul{
						display: none;
						margin-bottom: 0.5rem;
						padding-left: 30px;
						li.hasChildren{
							a{ 
								font-size: 1.3rem!important;
								line-height: normal;
							}
							ul{
								display: none;
								padding-left: 20px;				
								li.hasChildren{
									ul{
										display: none;
										margin-bottom: 1rem;
										padding-left: 5px;
										margin-left: 5px;
										border-top: 10px solid $color2!important;
										a{
											border: none;
											border-bottom: 3px solid lighten($color1,10%)!important;
											&:after{
												position: absolute;
												right: 0;
												top: 1.5rem;
												content:"\f105";
												font-family:fontAwesome;
												font-style:normal;
												color:#aaa;
												color: lighten($color2,15%);
												font-size:1.3rem;
												line-height:0;
												margin-right: 5px;
												margin-left: -15px;
											}
										}
									}
								}
							}
						}
						
					}
					> a{
						width: 100%;
						z-index:888!important;
						&:before{
							content:"\f107 ";
							font-family:fontAwesome;
							color: lighten($color2,15%);
//							color:#ccc;
							font-size:1.3rem;
							line-height:0;
							margin-right: 2px;
							margin-left: -15px;
						}
					}
					.uitklap{
//						display: none!important; /* HIDE THIS */
						display: block;
						position:absolute;
						z-index:999;
						top: 0;
//						right:0;
						left: -15px;
						padding: 0;
//						float : right;
						width: 100%;
//						height: 90%;
						height: 40px;
//						text-align: right;
						color: $color2;
						cursor : pointer;
					}
				}
			}

//			.sf-mega{ // NOT USED BUT DON'T DELETE
//				position: relative;
//				background:none!important;
//				.inner{
//					padding: 0!important;
//					ul{
//						ul{
//							display:block!important;
//						}
//					}
//				}
//				.inner >ul{
//					display: block!important;					
//					>li{
//						margin: 1rem 0!important;
//						li{
//							margin-bottom: 0!important;
//							&.active a{ 
//								color: $color1!important;
//								font-weight:300!important;
//							}
//						}
//					}
//				}
//				span{font-size: 1.2rem !important;}
//				a{
//					margin-left: 0.2rem;
//					padding: 0.25rem 0!important;
//					font-size: 1.1rem !important;					
//				}
//
//			}
		}
	}

 // MINORNAV
	.minornav{
		margin-top: 1.0rem;
		padding-top: 1.0rem;
		border-top: 1px solid $color2;
		.cntr_navminor{
			text-align: left!important;
			a{
				display: block;
				//min-height: 42px; margin: 16px 0; // === for Google Lighthouse
				font-size: 1.0rem!important;
				font-weight: 300;
				text-decoration: none;
//				color: white!important;
				padding: 0.85rem 0!important;
				border:0;
				white-space: normal;
				border-right: 0!important;
			}
		}
		ul{
			margin-left: 0;
			padding-left: 0;
			list-style-image: none;
			width:100%;

			li{
				position:relative;
				width:100%;
				padding-left: 0 !important;
				padding-right: 15px !important;
				border-bottom:1px dotted white;
				width: 100% !important;
				display: block;
				float : none;
				clear : both;
				list-style: none;
				&.hasChildren{
					> a{
						width: 100%;
						&:after{ content:"";}
						&:hover{
							background:none;
							color: white;
						}
					}
					.uitklap{
						display: none!important; /* HIDE THIS */
						position:absolute;
						z-index:999;
						top: 0;
						right:0;
						padding: 0;
						float : right;
						width: 100%;
						height: 90%;
						text-align: right;
						font-size: 20px;
						color: $color2;
						cursor : pointer;
						.fa{ margin-top: 10px;}
					}
//					ul{
//						position:inherit;
//						display: block!important;
//					}
				}
			}
		}
	}