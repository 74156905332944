footer{
    border-top: 1px solid $gray-500;
    font-size: 1.0rem;
    .container{
        display: flex;
        justify-content: space-between;
    }
    ul{
        display: flex;
        li{
            padding: .5rem;
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;

            }
        }
    }
    svg{
        max-width: 20px;
        max-height: 20px;
        fill: $color1;
        &:hover{
            fill:$color2;
        }
    }
    .footer--logos{
        display: flex;
        justify-content: space-between;
        margin: 0 -10px;
        img{
            max-height: 45px;
            margin: 0 15px;
        }
        a:first-child img{
            margin-left: 0;
        }
        a:last-child img{
            margin-right: 0;
        }
    }
}
@include media-breakpoint-down(md) {
    footer{
        .container{
            display: block;
        }
        .footer--logos{
            justify-content: flex-start;
            img{
                max-height: 40px;
                margin: 0 15px;
            }
            a:first-child img{
                margin-left: 15px;
            }
            a:last-child img{
                margin-right: 15px;
            }
        }
    }
}
@include media-breakpoint-down(sm) {
    footer{
        padding-bottom: 1rem;
        ul{
            display: flex;
            flex-wrap: wrap;
            li{
                padding: 0 .5rem;
                &:first-child {
                    padding-left: 0;
                    flex-basis: 100%;
                }
                &:nth-child(2){
                    padding-left: 0;
                }
                &:last-child {
                    padding-right: 0;

                }
            }
        }
    }
    footer .footer--logos{
        justify-content: space-between;
        img{
            max-height: 25px;
            margin: 0 15px;
        }
    }
}