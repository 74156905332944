/*!
 * Bootstrap v4.5.0 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

//
    // 1 - CORE
    // 2 - COMPONENTS
    // 3 - UTILITIES
 //


// 1 - CORE
 @import "functions";
 @import "variables";
 @import "mixins/mixins";
 @import "reboot";

 
// 2 - COMPONENTS
 @import "components/root";
 @import "components/type";
 @import "components/images";
 @import "components/code";
 @import "components/grid";
 @import "components/tables";
 @import "components/forms";
 @import "components/buttons";
 @import "components/transitions";
 @import "components/dropdown";
 @import "components/button-group";
 @import "components/input-group";
 @import "components/custom-forms";
 // @import "components/nav";
 // @import "components/navbar";
 // @import "components/card";
 @import "components/breadcrumb";
 // @import "components/pagination";
 // @import "components/badge";
 // @import "components/jumbotron";
 // @import "components/alert";
 // @import "components/progress";
 // @import "components/media";
 // @import "components/list-group";
 @import "components/close";
 // @import "components/toasts";
 @import "components/modal";
 // @import "components/tooltip";
 // @import "components/popover";
 // @import "components/carousel";
 // @import "components/spinners";
 @import "components/print";


// 3 - UTILITIES
@import "core/utilities/display";
@import "core/utilities/flex";
@import "core/utilities/spacing";
@import "core/utilities/text";
@import "core/utilities/sizing";