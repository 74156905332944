$color1:#333; //zwart
$color2:#1370B6; // blauw
$color3:#009FE3; // lichtblauw
$color4:#eee; // licht grijs


.color1{ color:$color1!important;}
.color2{ color:$color2!important;}
.color3{ color:$color3!important;}
.color4{ color:$color4!important;}
.white{ color:white!important;}

.bgcolor1{ background-image: none!important; background:$color1!important; color:white; h2, h3, a{color:white;}}
.bgcolor2{ background-image: none!important; background:$color2!important; color:white; h2, h3, a{color:white;}; .card{background-color: transparent;}}
.bgcolor3{ background-image: none!important; background:$color2!important; color:white; h2, h3, a{color:white;}; .card{background-color: transparent;}}
.bgcolor4{ background-image: none!important; background:$color4!important; }
.bgwhite{ background-image: none!important; background:white!important; }

img{
	max-width: 100%;
	height: auto;
}
