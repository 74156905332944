body{

    &.landing{
        height: 100vh;
        background: url("../images/TRH304.jpg") no-repeat center top;
        background-size: cover;
        main{
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            .logo{
                display: flex;
                justify-content: end;
                height: 30%;
                padding-left: 0;
                svg{
                    width: 300px;
                }
            }
            .content{
                display: flex;
                justify-content: center;
                align-items: center;
                height: 40%;
                text-align: center;
                &.white{
                    a{ color: white;}
                }
                &.black{
                    a{ color: $color1;}
                }
                a{
                    text-shadow: 2px 2px 5px rgba($color: $color1, $alpha: 0.2);
                    font-weight: 700;
                    font-size: 3rem;
                    text-decoration: none;
                    &:hover{
                        text-decoration: none;
                        text-shadow: 2px 2px 5px rgba($color: white, $alpha: 0.2);
                    }
                }
            }
            @include media-breakpoint-down(sm) {
                .logo svg{
                    width:80%;
                    margin: auto;
                }
                .content a{
                    font-size: 2.0rem;
                }
            }
        }

    }
}