
.cntr_header{
    margin-bottom: 1.5rem;
    // outline: 1px solid red;
    > div{
        align-items: flex-end;
    }
    &.container{
        padding-top: 0;
    }
    .row-top{
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;
    }
}
.logo{
    padding-bottom: .85rem;
    padding-left: 2.0rem;
}
@include media-breakpoint-down(sm) {
    .cntr_header{
        margin-bottom: 0;
        &.container{
            padding-top: 1.2rem;
            padding-bottom: 0.2rem;
        }
        .row-top{
            // justify-content: flex-end;
            margin-bottom: 0;
        }
    }
    .logo{
        padding-left: 0;
        padding-bottom: 0;
    }
}