
// FORMS
input[type=text],
input[type=email],
input[type=password]
{
    font-size: 0.95rem;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #ced4da;
    padding-left: 0;
    &:focus{
        outline: none; box-shadow: none;
        border-bottom: 1px solid $color3;
    }
}
.form-control{
    // outline: 1px solid red;
    padding: 0.1rem .75rem;
    height: inherit;
}
.form-group label{
    margin-bottom: 0;
    font-size: 0.95rem;
}
.form-row{
    margin-bottom: 2rem;
    // outline: 1px solid red;
    label{
        margin-bottom: 0;
        font-size: 0.95rem;
    }
}
form{
    .form-row{
        margin-right: -20px;
        margin-left: -20px;
        >[class*=col-]{
            padding-right: 20px;
            padding-left: 20px; 
        }
    }
}
.form-compact{
    .form-row{
        margin-bottom: 1rem;
        // outline: 1px solid red;
        label{
        }
    }
}