.breadcrumb{
	position: relative;
	background: $color4;
	border-radius: 0 0 6px 0;
	padding: 0 1rem;
	margin-bottom: 0;
	overflow: hidden;
	ul{
		display: flex;
		list-style: none;
		margin: 0;
		padding: 0;
		overflow: hidden;
		li{
			display: flex;
			align-items: center;
		  a{
			white-space: nowrap;
			text-decoration: none;
			padding: .5rem 0.6rem;
			font-size: 0.9rem;
			color: $gray-700;
			&:hover{
				color: $color2;
			}
		  }
		  &:after{
			  display: inline;
			  content:"▸";
			  color: $gray-600;
			  font-size: 1.2rem;

		  }
		  &:last-child:after{ display: none;}
		  &.selected{
			  a{
				  text-decoration: underline;
			}
		  }
		}
	  }
}

.account{
	display: flex;
	background: white;
	border-radius: 6px 0 0 0;

	svg{
		margin-right: 3px;
		height: 16px;
	}
	a{
		white-space: nowrap;
		display: flex;
		// align-items: center;
		text-decoration: none;
		padding: .5rem 1rem;
		text-transform: uppercase;
		font-size: 0.80rem;
		font-weight: 600;
		color: $gray-700;
		&:hover{
			color: $color2;
		}
	  }
	  
}
@include media-breakpoint-down(sm) {
    .account{
		a{
			padding: 1.5rem 0.25rem 0.5rem;
			color: $color2;
			font-weight: 600;
			font-size: 0.9rem;
		}
		svg{
			fill: $color2;
		}
	}
}