body{
    overflow-x: hidden;
}
main{
    margin-bottom: 2rem;
}
.container{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}
@include media-breakpoint-down(sm) {
    .container{
        padding-top: .5rem;
        padding-bottom: .5rem;
    }  
}
@include media-breakpoint-up(xl) {
    .container{
        max-width: 1400px;
    }
}
@include media-breakpoint-only(md) {
    .container{
        max-width: none;
    }
}

// IMAGE GALLERY
    .c-fancybox{
        position: relative;
        padding-top: 1.5rem;
        display:flex;
        margin-left: -0.5rem;
        margin-right: -0.5rem;
        &:before{
            content:"";
            position: absolute;
            background: $gray-400;
            height: 1px;
            width: calc(100% - 1rem);
            margin-top: -1.5rem;
            margin-left: 0.5rem;
        }
        a{
            display: inline-block;
            margin: 0.5rem;
            img{
                max-height: 80px;
            }
        }
    }
    @include media-breakpoint-down(lg) {
        .c-fancybox{
            flex-wrap: wrap;
            a{
                img{
                    max-height: 60px;
                }
            }
        }
    }
    @include media-breakpoint-down(sm) {
        .c-fancybox{
            flex-wrap: wrap;
            // outline: 1px solid blue;
            a{
                // outline: 1px solid orange;
                // background: $color4;
                text-align: center;
                // flex: 1 1 calc(25% - 1rem);
                width:  calc(25% - 1rem;
            }
        }
    }


// TEMPLATE CODE FOR MEDIA QUERIES
//@include media-breakpoint-up(xs) {}
//@include media-breakpoint-up(sm) {}
//@include media-breakpoint-up(md) {}
//@include media-breakpoint-up(lg) {}
//@include media-breakpoint-up(xl) {}


//@include media-breakpoint-down(xs) {}
//@include media-breakpoint-down(sm) {}
//@include media-breakpoint-down(md) {}
//@include media-breakpoint-down(lg) {}
// No media query necessary for xl breakpoint as it has no upper bound on its width


//@include media-breakpoint-only(xs) {}
//@include media-breakpoint-only(sm) {}
//@include media-breakpoint-only(md) {}
//@include media-breakpoint-only(lg) {}
//@include media-breakpoint-only(xl) {}
