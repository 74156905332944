// OVERVIEW 
.overview{
    .item{
        margin-bottom: 2rem;
        h2, h3, h4{
            margin-bottom: 0.25rem;
            font-size: 24px;
        }
        .title{
            margin-bottom: 1.5rem;
        }
        p{
            margin-bottom: 0.5rem;
        }
        @include media-breakpoint-down(sm) {
            margin-bottom: 3rem;
            h1, .h1{ font-size: 7vw; }
            h2, .h2{ font-size: 6vw; }
            h3, .h3{ font-size: 5vw; }
            .btn{
                margin-top: 0.4rem;
                margin-bottom: 0.4rem;
            }
            img{
                margin-bottom: 15px;
            }
        }
    }
    @include media-breakpoint-up(sm) {
        .item{
            h2, h3, h4{
                margin-top: -0.2rem;
            }
        }
    }
}

// OVERVIEW & FILTER
.overview--filter{
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    a{text-decoration: none;   }
    ul{
        margin: 0;
    }
}
@include media-breakpoint-down(md) {
    .overview--filter{
        flex-wrap: wrap;
        .overview--filter-dashboard{
            margin-left: auto;
        }
    }
}
.overview--filter-tabs, .overview--filter-dashboard{
    ul{
        display: flex;
        li{
            color: $color1;
            margin:0  0.2rem;
            &:first-child{margin-left: 0;}
            &:last-child{margin-right: 0;}
            &.selected a{
                color: $color2;
                border-bottom-color: $color2;
            }
            .btn{
                padding: .44rem .75rem;
                border-bottom:1px solid $color1; 
                &:hover{
                    color: $color2;
                    border-bottom-color: $color2;
                    text-decoration: none;
                } 
            }
            a{
                display: block;
                border-bottom:1px solid $color1;
                color: $color1;
                padding: .375rem .75rem;
                &:hover{
                    color: $color2;
                    border-bottom-color: $color2;
                    text-decoration: none;
                    svg{
                        fill:$color2;
                    }
                }
            }
        }
    }
}
.overview--filter-dashboard{
    .selected{
        svg{
            fill: $color2;
        }
    }
    .btn{
        border-radius: .35rem .25rem 0 0;
        &:focus{ outline: 0; box-shadow: none; }
    }
    .btn[aria-expanded="true"]{
        background-color:$color2;
        color: white;
        &:hover{
            color: white;
        }
      }
    .dropdown-menu{
        margin-top: 0;
        border: 1px solid rgba(0,0,0,.4);
        border-radius: 0 0 .25rem .25rem;
        padding: 0;
    }
    .dropdown-item{
        border-bottom: 1px solid rgba(0,0,0,.4);
        &:last-child{ border:0}
        span{
            display: inline-block;
            width: 45px;
            font-weight: bold;
        }
    }
}
.overview--grid{
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: -15px;
    a{ text-decoration: none;}
    .item{
        flex-basis: calc(33.333333%);
        margin-bottom: 1rem;
        padding:15px;
        &:hover{
            background-color: $color4;
        }
        a{
            display: inline-block;
            color:$color1;
            &:hover{
                text-decoration: none;
                color:$color2;
            }
        }
        img{ margin-bottom: 0.5rem;}
        span{ display: block;}
    }
    @include media-breakpoint-down(md) {
        .item{
            flex-basis: calc(50% - 20px);
        }
    }
    @include media-breakpoint-only(xs) {
        .item{
            flex-basis: 100%;
            margin: 0;
        }
    }
}